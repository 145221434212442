import React from "react";

import {
  ScrollContainer,
  HeroSlider,
  BishopsLetter,
  Officers,
} from "../components";

const Home = props => {
  const {
    news,
    officers,
    letter_en,
    letter_mal,
    videos,
    publications,
    advertisements,
    announcements,
    events,
  } = props;

  return (
    <>
      <HeroSlider data={news} />
      <BishopsLetter data_en={letter_en} data_mal={letter_mal} />
      <Officers data={officers} />
      <ScrollContainer
        videos={videos}
        publications={publications}
        advertisements={advertisements}
        announcements={announcements}
        events={events}
      />
    </>
  );
};

export default Home;
