import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components";
import Home from "./../container/home";
import { BACKEND_BASE_URL } from "../constant/constants";

const IndexPage = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Home" />
      <Home
        news={serverData.news}
        officers={serverData.officers}
        letter_en={serverData.bishops_letter_english}
        letter_mal={serverData.bishops_letter_malayalam}
        videos={serverData.videos}
        publications={serverData.publications}
        advertisements={serverData.advertisements}
        announcements={serverData.announcements}
        events={serverData.events}
      />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const bishops_letter_malayalam = await fetch(
      BACKEND_BASE_URL + "public-pages/bishops-letter-malayalam"
    );
    const bishops_letter_english = await fetch(
      BACKEND_BASE_URL + "public-pages/bishops-letter-english"
    );
    const officers = await fetch(
      BACKEND_BASE_URL + "people-by-head/people-home-page"
    );
    const news = await fetch(BACKEND_BASE_URL + "news?limit=10");
    const videos = await fetch(BACKEND_BASE_URL + "videos");
    const publications = await fetch(BACKEND_BASE_URL + "publications");
    const advertisements = await fetch(BACKEND_BASE_URL + "advertisements");
    const announcements = await fetch(BACKEND_BASE_URL + "announcements");
    const events = await fetch(BACKEND_BASE_URL + "events");

    if (!bishops_letter_malayalam.ok) {
      throw new Error(`Response failed`);
    }
    if (!bishops_letter_english.ok) {
      throw new Error(`Response failed`);
    }
    if (!officers.ok) {
      throw new Error(`Response failed`);
    }
    if (!news.ok) {
      throw new Error(`Response failed`);
    }
    if (!videos.ok) {
      throw new Error(`Response failed`);
    }
    if (!publications.ok) {
      throw new Error(`Response failed`);
    }
    if (!advertisements.ok) {
      throw new Error(`Response failed`);
    }
    if (!announcements.ok) {
      throw new Error(`Response failed`);
    }
    if (!events.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        bishops_letter_malayalam: await bishops_letter_malayalam.json(),
        bishops_letter_english: await bishops_letter_english.json(),
        officers: await officers.json(),
        news: await news.json(),
        videos: await videos.json(),
        publications: await publications.json(),
        advertisements: await advertisements.json(),
        announcements: await announcements.json(),
        events: await events.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default IndexPage;
